@import "common.scss";

.bookingsPageWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: $yellowprimary;
  width: 100%;
  padding-bottom: 120px;
  align-items: center;
  img {
    width: 25vw;
    height: auto;
  }
  .title {
    color: $redprimary;
    text-align: center;
  }

  .bookingGrid {
    display: grid;
    padding-left: 2.5%;
    padding-right: 2.5%;
    width: 93.8%;
    -moz-column-gap: 1.2%;
    column-gap: 1.2%;
    row-gap: 1.2%;
    grid-template-columns: auto auto auto auto;

    @include tablet() {
      -moz-column-gap: 2%;
      column-gap: 2%;
      row-gap: 1%;
      padding: 0 3%;

      grid-template-columns: auto auto;
    }

    @include mobile-large() {
      -moz-column-gap: 2%;
      column-gap: 2%;
      row-gap: 1%;
      padding: 0 3%;
      grid-template-columns: auto auto;
    }

    @include mobile() {
      -moz-column-gap: 2;
      column-gap: 2;
      row-gap: 1%;
      padding: 0 3%;
      grid-template-columns: auto;
    }
    .item {
      background-color: white;
      width: 100%;
      img {
        width: calc(100% - 52px);
        margin: 26px 26px;
        height: auto;
        border-radius: 5px;
      }
      .box {
        // height: 120px;
        width: calc(100% - 52px);
        margin: 4px 26px 13px 26px;
        font-size: 1.37rem;
        // padding-left: 3px;
        color: $redprimary;
        transform: translate3d(0, -5px, 0);

        // background-color: white;
        h2 {
          margin: 5px 0px;
          font-weight: 600;
        }

        text-align: center;

        .bookNowContainer {
          height: 70px;
          display: flex;
          position: relative;
          justify-content: center;
          align-items: center;
          margin: 5px 0px 0px 0px;
          cursor: pointer;
          --height: 70px;
          --stroke-color: var(--redprimary);
          --stroke-alternate-color: white;
          stroke-width: 2px;
          @include mobile-large {
            height: 50px;
            --height: 50px;
          }
          @include mobile {
            height: 70px;
            --height: 70px;
          }

          .bookNowText {
            margin-left: 20px;
            color: var(--stroke-color);
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
