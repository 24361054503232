@import "common.scss";

.aboutPageWrapper {
  background-color: $yellowprimary;
  position: relative;
  isolation: isolate;
  // overflow: hidden;

  --bord-rad: calc(40px + var(--wrap));

  --shift: calc(50vw * 1.29 * 0.25);

  // margin-bottom: calc(100vh - var(--bord-rad) - 20px);
  @include tablet() {
    --shift: calc(98vw * 1.29 * 0.25);
    --bord-rad: 0px;
    // margin-bottom:100vh;
  }
  // border-bottom-left-radius: var(--bord-rad);
  // border-bottom-right-radius: var(--bord-rad);

  .aboutMainContent {
    padding-top: var(--header-height);
    background-color: $yellowprimary;
    max-width: calc(100vw - 6px);
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: center;
    position: relative;
    isolation: isolate;
    overflow-x: hidden;
    .about_flex {
      z-index: 10;
      background-color: $redprimary;
      display: flex;
      color: white;
      width: 100%;
      justify-content: space-around;
      position: relative;
      h1 {
        padding-left: 1rem;
        
        font-size: 3.7rem;
      }

      h2 {
        padding-left: 1rem;
        font-size: 1.125rem;
        font-family: "Secondary";
        font-weight: 200;
        line-height: 1.5rem;
        padding-bottom: 1vw;
      }

      .text_right {
        margin-top: 2.5vw;
        max-width: 25%;
        margin-right: 8%;
      }

      @include laptop() {
        h1 {
          font-size: 2.7rem;
        }

        h2 {
          font-size: 1.125rem;
        }
      }

      @include tablet() {
        flex-direction: column;

        .text_right {
          max-width: 100%;
        }
      }

      @include mobile-large() {
        h1 {
          font-size: 2rem;
        }

        h2 {
          font-size: 1rem;
        }
      }

      @include mobile() {
        h1 {
          font-size: 1.5rem;
        }

        h2 {
          font-size: 0.75rem;
        }
      }
    }

    .mainImageLayersWrapper {
      // width: calc(100vw - 6px);
      width: 100%;
      // overflow-x: hidden;
      display: flex;
      position: relative;
      justify-content: center;
      --img-padding: calc(50vw * 1.29 * 0.25);
      .mainAboutImage {
        width: 50vw;
        position: relative;
        object-fit: cover;
        align-self: center;
        z-index: -1;
      }
      .mainAboutImage:nth-child(1) {
        width: 50vw;
        position: absolute;
        object-fit: cover;
        align-self: center;
        z-index: 1;
      }
      .backgroundCircle {
        position: absolute;
        // top: 0;
        bottom: var(--img-padding);
        // left: 0;
        // right: 0;
        width: 3000px;
        // height: max-content;
        height: 3000px;
        border-radius: 50%;
        overflow: hidden;
        // z-index: 0;
        // transform: translate(0%,-50%);
        display: flex;
        align-self: center;
        align-items: flex-end;
        justify-content: center;
        background-color: $redprimary;
        .mainAboutImage {
          position: absolute;
          bottom: 0;
          z-index: 10;
          transform: translate(0%, var(--img-padding));
        }
      }
    }
  }
  .aboutContent {
    // height: calc(100vh + var(--bord-rad) + var(--shift));
    --width: calc(100vw - var(--wrap) * 2);
    // padding-top: var(--shift);
    border-bottom-left-radius: calc(var(--bord-rad) - 20px) var(--bord-rad);
    border-bottom-right-radius: calc(var(--bord-rad) - 20px) var(--bord-rad);
    background-color: $yellowprimary;
    display: flex;
    position: relative;
    flex-direction: column;
    .aboutTempleContent {
      margin-top: 50px;
      display: flex;
      flex-direction: row;
      padding-left: var(--wrap);
      padding-right: var(--wrap);
      .photoSection {
        width: calc(var(--width) * 0.2608695652);
        // background-color: pink;
        // height: 90vh;
        margin-right: calc(var(--width) * 0.0362318841);
        padding-top: 4rem;
        img {
          position: sticky;
          top: var(--header-height);
          width: 100%;
          border-radius: 5px;
          border-top-right-radius: 15%;
        }
      }
      .contentSection {
        padding-top: 3rem;
        width: calc(var(--width) * 0.386);
        // background-color: green;
        // height: 90vh;
        color: $redprimary;
        h2 {
          font-size: 5.875rem;
          line-height: 90%;
          font-weight: 500;
          margin-bottom: 0;
        }
        .infoShloke {
          text-align: center;
          font-weight: 700;
          width: 100%;
        }
        p {
          font-size: 1.7rem;
          text-align: justify;
          font-family: "Secondary";
          font-weight: normal;
        }
      }
      .centerp{
        // p:nth-child(1){
        //   text-align: left;
        // }
        p{

          text-align: center;
        }
      }
      @include tablet {
        flex-direction: column;
        .photoSection {
          width: auto;
          // background-color: pink;
          // height: 90vh;
          // margin-right: calc(var(--width)*.0362318841);
          // padding-top: 4rem;
          padding: 3rem 0 0 0;
          margin-right: 0;
          img {
            width: 100%;
            border-radius: 5px;
          }
        }
        .contentSection {
          width: 100%;
          h2 {
            font-size: 2.5rem;
          }
          p {
            font-size: 1rem;
          }
        }
        
      }
    }
    .reverse {
      flex-direction: row-reverse;
      .photoSection {
        margin-right: 0;
        margin-left: calc(var(--width) * 0.0362318841);
        img {
          border-radius: 5px;
          border-top-left-radius: 15%;
        }
      }
      @include tablet {
        flex-direction: column;
        .photoSection {
          width: auto;
          padding: 3rem 0 0 0;
          margin-left: 0;
          img {
            width: 100%;
            border-radius: 5px;
          }
        }
        .contentSection {
          width: 100%;
        }
      }
    }
    .space {
      height: 150px;
      @include laptop(){
        height: 120px;
      }
      @include mobile(){
        height: 80px;
      }
    }
    

    .aboutmahantCard{
      max-width: 1018px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 var(--wrap);
      margin: 0 auto;
      gap: 1.5rem;

      @include laptop(){
        flex-direction: column;
        gap: 1rem;
        align-items: center;
      }
      

      .aboutMahantImage{
        height: 207px;
        border-top-left-radius: 40px;
        @include laptop(){
          height: auto;
          width: 315px;
        }
        @include mobile(){
          width: 215px;
        }
        
      }
      
      .aboutMahantContent{
        display: flex;
        flex-direction: column;
        justify-content: space-between ;

        @include laptop(){
          align-items: center;
          text-align: center;
        }

        .title{
          padding-top: 10px;
          font-size: 80px;
          font-weight: 500;
          color: $redprimary;
          font-family: "Secondary";
          @include laptop(){
            font-size: 40px;
          }
          @include mobile(){
            font-size: 28px;
          }
        }
        .body{
          font-size: 18px;
          font-family: "Secondary";
          font-weight: normal;
          color: #2F3943;
          line-height: 1.2;
          @include laptop(){
            font-size: 16px;
          }
          @include mobile(){
            font-size: 14px;
          }
        }
      }
    }
  }

  // background-color: $redprimary;

  .contactLayer {
    width: 100%;
    background-color: $redprimary;
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: 2px;
    height: calc(100vh - 5px);
    margin-top: calc(-1 * var(--bord-rad));
    top: 0;
    bottom: 0;
  }
}

.minus_index {
  // z-index: -1;
}

::-webkit-scrollbar-track {
  background: $yellowprimary;
  border-radius: 5px;
}
