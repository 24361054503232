
body {

  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
}

#root {
  --header-height: 5.625rem;
  --height: 2.5rem;
  --color-overlay: rgb(254 229 202/75%);
  --color-bg: #fee5ca;
  --color-contrast: #b00e2f;
  --color-light: #fff;
  --wrap: 2.5rem;
  --redprimary: #b00e2f;
  --yellowprimary: #fee5ca;
  -webkit-tap-highlight-color: transparent;
  /* overflow-x: hidden; */




}
@media screen and (max-width: 1200px) {
  #root {
    --wrap: 1.5rem;
  }
}

@media screen and (max-width: 900px) {
  #root {
    --wrap: 1rem;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'Secondary',
    monospace;
}

@font-face {
  font-family: 'Regular';
  src: local('Cervo-Regular'), url(./assets/fonts/Cervo-Regular.otf) format('opentype');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'Regular';
  src: local('Cervo-Medium'), url(./assets/fonts/Cervo-Medium.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Secondary';
  src: local('CervoHelveticaNeueCyr'), url(./assets/fonts/HelveticaNeueCyr-Medium.otf) format('opentype');
}

::-webkit-scrollbar {
  /* display: none; */
  width: 6px;
  position: absolute;
  left: 10;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(220, 220, 220, 0);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
