@import "common.scss";

.bookPage {
  background-color: $yellowprimary;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100dvh;
  &load {
    justify-content: center;
  }

  .bookPageContent {
    padding-top: var(--header-height);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-items: center;
    width: 70%;
    @include tablet {
      width: 90%;
    }
    .bookPageDetailsContainer {
      padding: 1rem 2rem;
      height: 200px;
      background-color: white;
      // width: 100%;
      display: flex;
      .bookPageImage {
        height: 100%;
        aspect-ratio: 3/4;
        object-fit: cover;
        border-radius: 5px;
      }
      .bookPageDetailsTextBox {
        width: auto;
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 2rem;
        justify-content: space-between;
        // background-color: palevioletred;
        .bookPageDetailsTitle {
          font-size: 3rem;
          font-weight: bold;
          color: $redprimary;
          @include tablet {
            font-size: 1.6rem;
          }
        }
        .bookPageDetailsBody {
          font-size: 1.7rem;
          font-weight: 500;
          color: #5b6874;
          max-width: 40%;
          overflow: hidden;
          text-align: start;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 3;
          -webkit-box-orient: vertical;
          @include tablet {
            font-size: 1.1rem;
            max-width: 100%;
          }
        }
      }
      .bookPageDetailsPriceBox {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        .bookPageDetailsPriceBoxPrice {
          display: flex;
          align-items: center;
          font-size: 2.5rem;
          font-weight: bold;
          color: $redprimary;
        }
        .bookPageDetailsPricePerPerson {
          font-size: 1.7rem;
          font-weight: 500;
          color: #5b6874;
        }
        @include tablet {
          display: none;
        }
      }
      @include tablet {
        height: 100px;
        padding: 0.5rem 0.5rem;
      }
    }
    .formContainer {
      width: 100%;
      display: grid;
      margin-top: 2rem;
      margin-bottom: 2rem;
      grid-template-columns: 1fr 1fr;
      row-gap: 1rem;
      column-gap: 1rem;
      @include tablet {
        grid-template-columns: 1fr;
      }
    }
    .numberOfPeopleCounter {
      display: flex;
      align-self: start;
      margin-top: 1rem;
      margin-bottom: 1rem;
      align-items: center;
      .numberOfPeopleCounterText {
        font-size: 1.7rem;
        font-weight: 500;
        color: $redprimary;
      }
      .counter {
        margin-left: 2rem;
        display: flex;
        background-color: white;
        border-radius: 5px;
        border: 1px solid $redprimary;
        // height: 2rem;
        align-items: center;
        overflow: hidden;
        .counterButton {
          width: 3rem;
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 2rem;
          padding-top: 10px;
          border-radius: 0 2px 2px 0;
          background-color: $redprimary;
          font-weight: 500;
          color: $yellowprimary;

          user-select: none;
          cursor: pointer;
          &:hover {
            background-color: $yellowprimary;
            color: $redprimary;
          }
        }
        :nth-child(1) {
          border-radius: 2px 0 0 2px;
        }

        .counterValue {
          min-width: 3rem;
          display: flex;
          padding-top: 10px;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .bookPageFinalFotter {
      width: 100%;
      display: flex;
      // flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;
      // position: fixed;
      // bottom: 0;

      .totalContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .totalText {
          font-size: 1.7rem;
          font-weight: 500;
          color: #5b6874;
          @include tablet {
            font-size: 1.2rem;
          }
        }
        .totalPrice {
          font-size: 2.5rem;
          font-weight: bold;
          display: flex;
          align-items: center;
          color: $redprimary;
          @include tablet {
            font-size: 2.7rem;
          }
        }
      }
      .buttonWrapper {
        margin-left: 1rem;
        display: flex;
        width: fit-content;
        padding: 0;
        height: 3.5rem;
        .paymentButtons {
          height: 3rem;
          font-size: 1.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          color: var(--btcolor);
          @include tablet {
            font-size: 1.2rem;
          }
          // background-color: var(--btBgcolor);
        }
      }
    }
  }
}
