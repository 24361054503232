@import "common.scss";
$redprimary: #b00e2f;
$yellowprimary: #fee5ca;

.homePage2Wrapper {
  max-width: 100%;
  // height: 2000vh;
  display: flex;
  flex-direction: column;
  position: relative;
  // background-color: red;
  isolation: isolate;
  background-color: $yellowprimary;

  .shlokeLayer {
    background-color: $yellowprimary;
    // display: flex;
    position: relative;
    max-width: 100%;
    height: 200vh;
    // background-color: blue;
    @include tablet() {
      height: calc(100vh + 50rem);
    }
    @include mobile() {
      height: calc(200vh + 20rem);
    }

    .homeTitleContentWrapper {
      position: absolute;
      top: 0;
      height: 100svh;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      display: flex;
      flex-direction: column;
      padding-left: calc(var(--wrap) * 3);
      color: white;
      justify-content: center;

      div {
        overflow: hidden;
        background: transparent;
      }

      h1 {
        background: transparent;
        margin-top: 10px;
        margin-bottom: 0;
        align-content: center;
        line-height: 10rem;
        font-size: 10rem;
        text-justify: inter-ideograph;
        -webkit-animation: slide-and-rotate 2s forwards;
        animation: slide-and-rotate 2s forwards;
        transform-origin: center left;
        -webkit-animation-timing-function: cubic-bezier(
          0.175,
          0.885,
          0.32,
          1.075
        );
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.075);

        @include laptop() {
          font-size: 7rem;
          line-height: 7rem;
        }

        @include tablet() {
          font-size: 5rem;
          line-height: 5rem;
        }

        @include mobile() {
          font-size: 4rem;
          line-height: 4rem;
        }
      }
      @include tablet() {
        padding-left: calc(var(--wrap) * 2.5 + 20px);
      }

      @include mobile-large() {
        padding-left: calc(var(--wrap) * 2 + 10px);
      }

      justify-content: flex-end;

      @include mobile() {
        padding-left: calc(var(--wrap) * 2);
        height: calc(100svh - var(--wrap));
      }
    }

    .mainImgWrapper {
      position: -webkit-sticky;
      // background-color: green;
      position: sticky;
      height: 100svh;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .maaPic {
        -o-object-fit: cover;
        object-fit: cover;
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
      @include tablet() {
        margin-bottom: 30rem;
      }
      @include mobile() {
        margin-bottom: 20rem;
      }
    }
    .shlokeWrapper {
      position: absolute;
      // top: -220px;
      // top:-40%;
      width: 100%;
      bottom: 0;

      // overflow: hidden;
      overflow: hidden;
      // translate: 40% 0;
      // background-color: green;
      padding-top: 3rem;
      padding-bottom: 2rem;
      .marquee {
        position: relative;
        margin-top: 0;
        white-space: nowrap;
        display: flex;
        font-size: 9rem;
        --marquee-rotate: -3deg;
        --marquee-from: 0%;
        --marquee-to: calc(-700%);
        -webkit-animation: marquee 100s linear infinite;
        animation: marquee 100s linear infinite;
        line-height: 0rem;
        font-weight: bold;
        // margin-top: 3rem;
        @include tablet() {
          font-size: 6.5rem;
          margin-top: 2rem;
          -webkit-animation: marquee 50s linear infinite;
          animation: marquee 50s linear infinite;
        }

        @include mobile() {
          font-size: 3.5rem;
          // line-height: 4rem;
          margin-top: 1rem;
          -webkit-animation: marquee 30s linear infinite;
          animation: marquee 30s linear infinite;
        }

        &reverse {
          animation-direction: reverse;
          // transform: translateX(20%);
          translate: 50% -20%;
        }

        p {
          display: inline-block;
          padding-left: 120px;

          @include tablet() {
            margin: 10px;
            line-height: 7rem;
          }
          @include mobile() {
            margin: 0;
            line-height: 5rem;
          }
        }

        p:nth-child(odd) {
          color: $redprimary;
        }

        p:nth-child(even) {
          color: transparent;
          // color: green;
          -webkit-text-stroke: 4px $redprimary;
          border-color: $redprimary;

          @include tablet() {
            -webkit-text-stroke: 2px $redprimary;
          }

          @include mobile() {
            -webkit-text-stroke: 1px $redprimary;
          }
        }
      }
    }
  }

  .infoWrapper {
    background-color: $redprimary;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .infoTitle {
      position: relative;
      align-self: center;
      color: white;
      // align-items: center !important;
      // align-self: center !important;
      // justify-content: center !important;
      // justify-self: center;
      padding-top: 25px;
      text-align: center;
      // padding-left: auto;
      // padding-right: auto;
      width: 100%;
      font-size: 9rem;

      @include laptop() {
        // font-size: 5.87rem;
        font-size: 15vh;
      }

      @include tablet() {
        // font-size: 3.5rem;
        font-size: 10vh;
      }

      @include mobile-large() {
        font-size: 6 vh;
      }

      @include mobile {
        font-size: 6vh;
      }
    }
    img {
      width: 25vw;
      height: auto;
    }
    .infoGrid {
      display: flex;
      flex: auto;
      position: relative;
      flex-direction: row;
      height: auto;
      overflow-y: hidden;
      align-items: stretch;

      .mainContent {
        display: flex;
        flex-direction: column;
        width: 44%;
        height: 100%;
        // justify-content: center;
        align-items: center;
        flex-wrap: content;
        position: relative;
        // margin-top: 330px;
        h2 {
          align-self: flex-start;
        }

        .infoShloke {
          align-self: center;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          text-align: center;
        }
        @include laptop() {
          // margin-top: 240px;
        }

        @include tablet() {
          width: 100%;
          // margin-top: 200px;
        }

        @include mobile-large() {
          width: 100%;
          // margin-top: 120px;
        }

        @include mobile {
          width: 100%;
          // margin-top: 100px;
        }

        align-items: center;
        padding: 10px !important;

        .mainImage {
          -o-object-fit: cover;
          object-fit: cover;
          // flex: content;
          align-content: center;
          margin: 10px;
          width: 100%;
          // height: 200px;
          box-sizing: border-box;
          border-radius: 50vmax 50vmax 0 0;
          padding: 10px;
          overflow: hidden;

          img {
            // object-fit: ;
            // flex: content;
            // margin: 10px;
            width: 100%;
            // animation: opacity-out 3s ease forwards;
            // height: 200px;
            // box-sizing: border-box;
            // border-radius: 50vmax 50vmax 0 0;
            // padding: 10px;
          }
        }

        h2 {
          color: white;
          margin: 0;
          font-size: 2.5rem;
          margin-top: 3rem;
          margin-bottom: 2rem;
          font-weight: bold;
          @include tablet() {
            font-size: 2.5rem;
          }
          @include mobile() {
            font-size: 2.5rem;
          }
        }

        P {
          font-weight: normal;
          color: white;
          margin: 0;
          font-size: 1.75rem;
          color: white;
          text-align: justify;

          @include tablet() {
            font-size: 2rem;
          }

          @include mobile() {
            font-size: 2rem;
          }
        }

        .moreWrapper {
          // width: 100%;
          margin-right: auto;
          height: 100px;
          display: flex;
          position: relative;
          flex: 1 1 auto;
          left: 0;
          // align-self: center;
          justify-content: flex-start;
          align-items: center;
          padding-bottom: 10px;
          --stroke-color: white !important;
          --stroke-alternate-color: var(--redprimary);
          cursor: pointer;
          --height: 100px;

          @include mobile {
            height: 70px;
            --height: 70px;
          }
          .circleAnimationSvgWrapper {
            align-self: center;
            --stroke-color: white !important;
          }
          .readMoreText {
            margin-left: 20px;
            color: var(--stroke-color);
            font-size: 1.5rem;
          }
        }
      }

      .sideImageWrapper {
        display: flex;
        flex: content;
        width: 28%;
        justify-content: center;

        .sideImage {
          -o-object-fit: contain;
          object-fit: contain;
          height: 100px;
          width: 25vw;

          &__1 {
            position: absolute;
            border-radius: 10% 0 0 0;
            bottom: 25%;
          }

          &__2 {
            position: absolute;
            border-radius: 0 10% 0 0;
            bottom: -10%;
          }
        }

        .more {
          position: absolute;
          // left: -10px;
          -o-object-fit: contain;
          object-fit: contain;
          // height: 200px;
          width: 25vw;
          position: absolute;
          bottom: -11%;
          cursor: pointer;
          color: white;

          @include tv() {
            font-size: 22px;
            bottom: -12%;
          }
        }
      }
    }
    .collageWrapper {
      display: inline-block;
      white-space: nowrap;
      width: 100%;
      position: relative;
      overflow-x: hidden;

      .collage {
        overflow-x: hidden;
        // display: flex;
        // overflow: hidden;
        display: inline-block;
        position: relative;
        flex: initial;
        // padding-bottom: 10px;
        --marquee-rotate: 0deg;
        --marquee-from: 0%;
        --marquee-to: calc(-100% + 100vw);

        -webkit-animation: marquee 50s linear alternate infinite;
        animation: marquee 50s linear alternate infinite;

        // animation-play-state: paused;
        // width: calc(calc(25vw + 10px) * 16);
        width: -webkit-min-content;
        width: -moz-min-content;
        width: min-content;
        margin-top: 1rem;
        overflow: hidden;

        img {
          padding-left: 20px;
          // width: 25vw;
          height: auto;
          aspect-ratio: 3/4;

          @include mobile-large {
            padding-left: 10px;
            width: 40vw;
          }
          @include mobile {
            padding-left: 8px;
            width: 40vw;
          }
        }
        img:nth-child(odd) {
          border-radius: 10% 0 0 0;
        }

        img:nth-child(even) {
          border-radius: 0 10% 0 0;
        }
      }
    }
  }

  .activityWrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    background-color: $yellowprimary;
    min-height: 100dvh;

    img {
      width: 25vw;
      height: auto;
    }

    .title {
      padding-top: 60px;
      padding-bottom: 60px;

      color: $redprimary;
      // font-size: 5rem;
      text-align: center;
    }

    .activityGrid {
      display: grid;
      padding-left: 2.5%;
      padding-right: 2.5%;
      padding-bottom: 50px;
      width: 93.8%;
      -moz-column-gap: 1.2%;
      column-gap: 1.2%;
      row-gap: 1.2%;
      grid-template-columns: auto auto auto auto;

      @include tablet() {
        -moz-column-gap: 2%;
        column-gap: 2%;
        row-gap: 1%;
        grid-template-columns: auto auto;
      }

      @include mobile-large() {
        -moz-column-gap: 2%;
        column-gap: 2%;
        row-gap: 1%;
        grid-template-columns: auto auto;
      }

      @include mobile() {
        -moz-column-gap: 2%;
        column-gap: 2%;
        row-gap: 1%;
        padding-bottom: 70px;
        grid-template-columns: auto;
      }
      .item {
        background-color: white;
        width: 100%;
        img {
          width: calc(100% - 52px);
          margin: 26px 26px;
          height: auto;
          border-radius: 5px;
        }
        .box {
          // height: 120px;
          width: calc(100% - 52px);
          margin: 4px 26px 13px 26px;
          font-size: 1.37rem;
          // padding-left: 3px;
          color: $redprimary;
          transform: translate3d(0, -5px, 0);

          // background-color: white;
          h2 {
            margin: 5px 0px;
            font-weight: 600;
          }

          text-align: center;

          .bookNowContainer {
            height: 70px;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            margin: 5px 0px 0px 0px;
            cursor: pointer;
            --height: 70px;
            --stroke-color: var(--redprimary);
            --stroke-alternate-color: white;
            stroke-width: 2px;
            @include mobile-large {
              height: 50px;
              --height: 50px;
            }
            @include mobile {
              height: 70px;
              --height: 70px;
            }

            .bookNowText {
              margin-left: 20px;
              color: var(--stroke-color);
              font-size: 1.5rem;
            }
          }
        }
        // img:nth-child(odd) {
        //     border-radius: 10% 0 0 0;
        // }

        // img:nth-child(even) {
        //     border-radius: 0 10% 0 0;
        // }
      }
    }
  }

  .bookingWrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: $yellowprimary;
    width: 100%;
    padding-bottom: 50px;
    align-items: center;
    min-height: 100dvh;

    img {
      width: 25vw;
      height: auto;
    }

    .title {
      padding-top: 60px;
      padding-bottom: 60px;

      color: $redprimary;
      // font-size: 5rem;
      text-align: center;
    }

    padding-bottom: 120px;
    .bookingGrid {
      display: grid;
      padding-left: 2.5%;
      padding-right: 2.5%;
      padding-bottom: 50px;
      width: 93.8%;
      -moz-column-gap: 1.2%;
      column-gap: 1.2%;
      row-gap: 1.2%;
      grid-template-columns: auto auto auto auto;

      @include tablet() {
        -moz-column-gap: 2%;
        column-gap: 2%;
        row-gap: 1%;
        grid-template-columns: auto auto;
      }

      @include mobile-large() {
        -moz-column-gap: 2%;
        column-gap: 2%;
        row-gap: 1%;
        grid-template-columns: auto auto;
      }

      @include mobile() {
        -moz-column-gap: 2;
        column-gap: 2;
        row-gap: 1%;
        grid-template-columns: auto;
        padding-bottom: 80px;
      }
      .item {
        background-color: white;
        width: 100%;
        img {
          width: calc(100% - 52px);
          margin: 26px 26px;
          height: auto;
          border-radius: 5px;
        }
        .box {
          // height: 120px;
          width: calc(100% - 52px);
          margin: 4px 26px 13px 26px;
          font-size: 1.37rem;
          // padding-left: 3px;
          color: $redprimary;
          transform: translate3d(0, -5px, 0);

          // background-color: white;
          h2 {
            margin: 5px 0px;
            font-weight: 600;
          }

          text-align: center;

          .bookNowContainer {
            height: 70px;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            margin: 5px 0px 0px 0px;
            cursor: pointer;
            --height: 70px;
            --stroke-color: var(--redprimary);
            --stroke-alternate-color: white;
            stroke-width: 2px;
            @include mobile-large {
              height: 50px;
              --height: 50px;
            }
            @include mobile {
              height: 70px;
              --height: 70px;
            }

            .bookNowText {
              margin-left: 20px;
              color: var(--stroke-color);
              font-size: 1.5rem;
            }
          }
        }
        // img:nth-child(odd) {
        //     border-radius: 10% 0 0 0;
        // }

        // img:nth-child(even) {
        //     border-radius: 0 10% 0 0;
        // }
      }
    }
  }

  .mapWrapper {
    display: flex;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    // justify-content: space-between;
    background-color: $yellowprimary;

    // align-items: center;
    @include tablet() {
      flex-direction: column;
    }

    .content {
      display: flex;
      flex-direction: column;
      width: 35%;
      padding-left: 50px;
      margin-top: 200px;
      color: $redprimary;

      .showOnMapWrapper {
        width: 100%;
        height: 100px;
        display: flex;
        position: relative;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        --height: 100px;
        --stroke-color: var(--redprimary);
        --stroke-alternate-color: white;

        @include mobile {
          height: 70px;
          --height: 70px;
        }

        .showOnMapText {
          margin-left: 20px;
          color: var(--stroke-color);
          font-size: 1.5rem;
        }
      }

      h3 {
        font-size: 3.1rem;
      }

      @include laptop() {
        h3 {
          font-size: 2rem;
        }

        // width: ;
      }

      @include tablet() {
        padding-left: 30px;
        margin-top: 0px;
        width: 100%;
        height: auto;

        h3 {
          margin-top: 0;
          font-size: 2.2rem;
        }
      }
    }

    .mapSvgWrapper {
      position: relative;
      display: flex;
      // justify-content: flex-end;
      -o-object-fit: contain !important;
      object-fit: contain !important;
      -o-object-position: center;
      object-position: center;
      flex: 1 1 auto !important;
      // flex:auto ;

      // div {
      //     // width: 100%;

      //     background-color: palegreen;
      //     object-fit: contain;
      //     display: flex;
      //     position: relative;
      // }
      //     img{
      //         max-width: 100%;
      //         max-height:calc(100vh - 350px);
      //         // display: none ;
      //         object-fit: contain;
      //         flex: 1;
      //     }

      padding: 5vh;
      @include tablet() {
        padding: 4vh;
      }

      .mapSvg {
        display: flex;
        // max-height: 85vh;
        // max-width: 65%;
        -o-object-position: center;
        object-position: center;
        flex: 1 1 auto;
        // padding-left: auto;
        // align-items: flex-end;
        // justify-self: end;
        // align-self: center;
        width: 100%;
        max-height: 90vh;

        position: relative;

        #mapTemple {
          -webkit-animation: scale-up-and-down 0.6s ease alternate infinite;
          animation: scale-up-and-down 0.6s ease alternate infinite;
          transform-origin: center center;
          cursor: pointer;
        }

        // @include laptop() {
        //     max-width: 60%;
        // }

        @include tablet() {
          max-height: calc(90vh - 340px);
          max-height: calc(100svh - 300px);
        }

        @-webkit-keyframes scale-up-and-down {
          0% {
            transform: scale(1.1, 1.1) translateY(2.1%);
          }

          100% {
            transform: scale(0.9, 0.9) translateY(1%);
          }
        }

        @keyframes scale-up-and-down {
          0% {
            transform: scale(1.1, 1.1) translateY(-0.1%);
          }

          100% {
            transform: scale(0.9, 0.9) translateY(-1.1%);
          }
        }
      }
    }
  }

  .downloadWrapper {
    display: flex;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    // justify-content: space-between;
    background-color: $yellowprimary;
    align-items: center;
    justify-content: center;
    .downloadConatiner {
      max-width: 1240px;
      padding-left: 40px;
      padding-right: 40px;
      width: 100%;
      display: flex;
      // background-color: rgba(255, 0, 0, 0.119);
      gap: 100px;
      overflow-y: hidden;
      .dbox {
        // background-color: rgba(152, 251, 152, 0.204);
        height: 100%;
        width: 100%;
      }
      .dbox1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        padding: 0 110px;
        @include laptop() {
          padding: 0 40px;
        }
        @include tablet() {
          padding: 30px 30px;
        }
        @include mobile() {
          padding: 20px 0px;
        }
        h3 {
          font-size: 52.8px;
          line-height: 50px;
          text-transform: capitalize;
          color: $redprimary;
          margin-bottom: 10px;
        }
        p{
          font-size: 16.8px;
          font-weight: lighter;
          color: #2F3943;
          margin-bottom: 40px;
        }
      }
      .dbox2 {
        display: flex;
        @include tablet() {
          display: none;
        }
        // padding-top: 20%;
        justify-content: center;
        img {
          margin-top: 20dvh ;
          max-height: 75dvh;
          object-fit: contain;
          transform: translateY(10px);
        }
      }
    }
  }

  .contactWrapper {
    height: 100vh;
    background-color: $redprimary;
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: 2px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: -1;
  }

  @-webkit-keyframes marquee {
    0% {
      // transform: rotate(var(--marquee-rotate)) translate3d(0%, 0, 0);
      transform: rotate(var(--marquee-rotate))
        translate3d(var(--marquee-from), 0, 0);
    }

    100% {
      // transform: rotate(var(--marquee-rotate)) translate3d(-800%, 0, 0);
      transform: rotate(var(--marquee-rotate))
        translate3d(var(--marquee-to), 0, 0);
    }
  }

  @keyframes marquee {
    0% {
      // transform: rotate(var(--marquee-rotate)) translate3d(0%, 0, 0);
      transform: rotate(var(--marquee-rotate))
        translate3d(var(--marquee-from), 0, 0);
    }

    100% {
      // transform: rotate(var(--marquee-rotate)) translate3d(-800%, 0, 0);
      transform: rotate(var(--marquee-rotate))
        translate3d(var(--marquee-to), 0, 0);
    }
  }

  @-webkit-keyframes marquee-reverse {
    0% {
      transform: translate3d(-800%, 0, 0);
    }

    100% {
      transform: translate3d(0%, 0, 0);
    }
  }

  @keyframes marquee-reverse {
    0% {
      transform: translate3d(-800%, 0, 0);
    }

    100% {
      transform: translate3d(0%, 0, 0);
    }
  }
}

@-webkit-keyframes slide-and-rotate {
  0% {
    transform: translate3d(0, 100%, 0) rotateZ(30deg);
    // transform:  rotateZ(10deg);
  }

  100% {
    // transform:  rotateZ(2deg);
    transform: translate3d(0%, 0, 0) rotateZ(0deg);
  }
}

@keyframes slide-and-rotate {
  0% {
    transform: translate3d(0, 100%, 0) rotateZ(30deg);
    // transform:  rotateZ(10deg);
  }

  100% {
    // transform:  rotateZ(2deg);
    transform: translate3d(0%, 0, 0) rotateZ(0deg);
  }
}

@-webkit-keyframes zoom-out {
  0% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes zoom-out {
  0% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes opacity-out {
  0% {
    transform: scale(1.1);
    opacity: 0.3;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes opacity-out {
  0% {
    transform: scale(1.1);
    opacity: 0.3;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes opacity-in {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.1);
    opacity: 0.3;
  }
}

@keyframes opacity-in {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.1);
    opacity: 0.3;
  }
}

@-webkit-keyframes slide-from-left {
  0% {
    transform: translateX(100%);
    opacity: 0.3;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-from-left {
  0% {
    transform: translateX(100%);
    opacity: 0.3;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-from-left-exit {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0.3;
  }
}

@keyframes slide-from-left-exit {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0.3;
  }
}
