$redprimary: #b00e2f;
$yellowprimary: #fee5ca;
$header-height: 5.625rem;

::-moz-selection {
  /* Code for Firefox */
  background: #d4bda48a;
}

::selection {
  background: #d4bda48a;
}
@mixin mobile {
  @media screen and (max-width: 453px) {
    @content;
  }
}

@mixin mobile-large {
  @media screen and (max-width: 650px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 900px) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin tv {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@font-face {
  font-family: "Cervo";
  src: local("Cervo-Regular"),
    url(../assets/fonts/Cervo-Regular.otf) format("otf");
}

.buttonWrapper {
  overflow-y: hidden;
  height: 44.5px;
  display: inline-block;
  position: relative;
  border-radius: 1px;
  border: none;
  // border-top-left-radius: 4px;
  background: transparent;
  padding: 0;

  .button {
    // --btcolor: #b00e2f;
    --btcolor: var(--btPrimary);
    // --btBgcolor: #fee5ca;
    --btBgcolor: var(--btSecondary);
    background-color: var(--btBgcolor);
    // padding: 20px;
    display: flex;
    align-items: center;
    align-content: center;
    padding-left: 10.625px;
    padding-right: 10.625px;
    font-family: "Secondary";
    // color: white;
    height: 42px;
    border-top: 0.1px solid var(--btcolor);
    border-left: 0.1px solid var(--btcolor);
    border-right: 2px solid var(--btcolor);
    border-bottom: 3px solid var(--btcolor);
    // box-shadow:  -1px 1px #77aaff,
    //      -2px 2px #77aaff,
    //      -3px 3px #77aaff,
    //      -4px 4px #77aaff,
    //      -5px 5px #77aaff;
    cursor: pointer;
    transition: 500ms;
    transition: transform ease 500ms;
    transform: translate(0, 0px);

    overflow: hidden;

    .box {
      border-top-left-radius: 4px;

      transition: 500ms;
      position: relative;
      top: 100%;
      left: 0;
      height: calc(100% - 2px);
      // width: calc(100% - 1px);
      background-color: var(--btBgcolor);
    }

    @include tablet() {
      height: 30px;
      padding: 0 5px;
    }
  }
}

.buttonWrapper:hover {
  .button {
    border: 0;
    --btBgcolor: #b00e2f;
    --btcolor: #fee5ca;
    // --btcolor: var(--btPrimary);
    // --btBgcolor: var(--btSecondary);
    background-color: var(--btcolor);
    overflow: hidden;

    .box {
      position: absolute;
      top: 0;
      left: 1px;
      width: calc(100% - 2px);
      z-index: -1;
      background-color: var(--btBgcolor);
      // padding-bottom: 2px;
      border-top: 0.1px solid var(--btPrimary);
      border-left: 1px solid var(--btPrimary);
      border-right: 1px solid var(--btPrimary);
      border-bottom: 2px solid var(--btPrimary);
    }

    transform: translate(0, 2px);
  }
}

.styles_menu_popup__OlPjw {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
  // display: block;
  color: var(--color-light);
  --color-text: var(--color-light);
  --color-text-hover: var(--color-bg);
  --button-stroke-size: 2.5rem;
  --button-stroke-bg: var(--color-contrast);
  --button-stroke-stroke: var(--color-bg);
  --button-stroke-stroke-hover: var(--color-bg);
  --button-stroke-plane: var(--color-bg);
  --button-stroke-clip: var(--color-bg);
  --button-stroke-text: var(--color-bg);
  --button-stroke-text-hover: var(--color-contrast);
}

.styles_menu_popup__OlPjw.styles_show__rY79t {
  display: block;
}

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  // background-color: var(--color-overlay);
  z-index: 11;
  display: none;
  // cursor: pointer;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.overlayBackGround {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  opacity: 1;
  cursor: pointer;
  // transition: opacity 0.5s ease;
  // animation: opacity-out 1.5s ease ;
  -webkit-animation-name: opacity-in 1s ease;
  animation-name: opacity-in 1s ease;
  background-color: var(--color-overlay);
}

.overLayMenu {
  position: absolute;
  // background-color: $yellowprimary;
  // height: 50%;
  right: 0;
  top: 0;
  z-index: 12;
  overflow: hidden;
  border-bottom-left-radius: 50px;
  --btBgcolor: #b00e2f;
  --btcolor: #fee5ca;
  --btPrimary: #b00e2f;
  --btSecondary: #fee5ca;
  width: min(500px, 97vw);

  .circle {
    position: absolute;
    background-color: $redprimary;
    aspect-ratio: 1/1;
    border-radius: 50%;
    height: 100%;
    top: -50%;
    z-index: -1;
    right: -50%;
    // top:0;
    // right:0;
    transition: height cubic-bezier(0, 0, 0.58, 1) 0.5s;
  }

  .buttonWrapper {
    position: absolute;
    top: calc((var(--header-height) - var(--height)) / 2);
    right: var(--wrap);

    .menu {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;

      .text {
        padding-right: 10.625px;
        font-size: 0.9375rem;
        padding-top: 5px;
        color: var(--btcolor);

        @include tablet() {
          display: none;
        }
      }

      .cross {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 1.6rem;
        transform: scaleY(0.8);
        font-weight: 100;
        color: var(--btcolor);
      }
    }

    @include tablet() {
      height: 35px;
      padding: 0 0px;

      .menu .cross {
        padding: 0px 5px;
      }
    }
  }

  .menuUl {
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    font-size: calc((54 / 810) * 100vh);
    font-family: "Regular";

    li {
      overflow: hidden;
      line-height: calc((54 / 810) * 100vh);
      position: relative;
      padding-top: 10px;

      div {
        cursor: pointer;
        position: relative;
        height: 100%;
        -webkit-animation: text-up-animation 0.5s forwards;
        animation: text-up-animation 0.5s forwards;
        transform: translateY(110%);
      }

      -webkit-animation-timing-function: cubic-bezier(
        0.175,
        0.885,
        0.32,
        1.175
      );

      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.175);

      // @for $i from 1 through 6 {
      //     div:nth-child(#{$i}) {
      //     animation-delay:   calc( 0.5s * $i)  ;
      //     //   background-color: lighten($base-color, $i * 5%);
      //     }
      //   }
      :hover {
        color: $yellowprimary;
      }
    }
  }

  @-webkit-keyframes text-up-animation {
    from {
      transform: translateY(110%);
    }

    to {
      transform: translateY(0%);
    }
  }

  @keyframes text-up-animation {
    from {
      transform: translateY(110%);
    }

    to {
      transform: translateY(0%);
    }
  }

  @-webkit-keyframes text-down-animation {
    from {
      transform: translateY(0%);
    }

    to {
      transform: translateY(1100%);
    }
  }

  @keyframes text-down-animation {
    from {
      transform: translateY(0%);
    }

    to {
      transform: translateY(1100%);
    }
  }

  // --wrap:2.5rem;

  // @include tv() {
  //     --wrap: 2.5rem
  // }

  // @include laptop() {
  //     --wrap: 2.5rem
  // }
}

.appBar {
  position: fixed;
  height: 0;
  background-color: #b00e2f;
  // width: 100%;
  left: var(--wrap);
  right: var(--wrap);
  display: flex;
  z-index: 10;
  justify-content: space-between;

  .title {
    z-index: 10;
    position: relative;
    top: calc((var(--header-height) - var(--height) - 10px) / 2);
    cursor: pointer;
    display: flex;
    img {
      height: 55px;
      width: 55px;
    }
  }

  .actions {
    display: flex;
    // right: var(--wrap);
    font-family: "Secondary";
    z-index: 10;
    position: relative;
    top: calc((var(--header-height) - var(--height)) / 2);

    .menu {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;

      .text {
        padding-right: 10.625px;
        font-size: 0.9375rem;
        padding-top: 5px;
        color: var(--btcolor);

        @include tablet() {
          display: none;
        }
      }

      .lines {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 1rem;
        color: var(--btcolor);
        div {
          background-color: var(--btcolor);
          // background-color: $yellowprimary;
          width: 31.8px;
          height: 2px;
        }

        @include tablet() {
          div {
            width: 25px;
          }
        }
      }
    }
  }

  // --wrap:2.5rem;

  // @include tv() {
  //     --wrap: 2.5rem
  // }

  // @include laptop() {
  //     --wrap: 2.5rem
  // }
}

.logInComponent {
  position: absolute;
  right: 0;
  top: 0;
  width: 33vw;
  bottom: 0;
  background-color: #ffffff;
  z-index: 11;
  -webkit-animation: slide-from-left 0.5s ease forwards;
  animation: slide-from-left 0.5s ease forwards;

  @include tablet() {
    width: 100%;
  }
  #sendOrRequestAgain {
    display: flex;
    align-content: center;
    #RequestOtpAgain {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      // height: 100%;
      margin-top: 1rem;
      margin-left: 1rem;
      color: $redprimary;
      text-decoration: underline;
      transform: translateX(100%);
      -webkit-animation: slide-from-left 0.7s 0.3s ease forwards;
      animation: slide-from-left 0.7s 0.3s ease forwards;
    }
  }
  .buttonWrapper {
    position: absolute;
    top: calc((var(--header-height) - var(--height)) / 2);
    right: var(--wrap);
    transform: translateX(100%);
    -webkit-animation: slide-from-left 0.7s 0.3s ease forwards;
    animation: slide-from-left 0.7s 0.3s ease forwards;
    opacity: 0.3;
    z-index: 13;

    .closeBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      cursor: pointer;

      .cross {
        padding-left: 5.625px;
        padding-right: 5.625px;
        font-size: 0.9375rem;
        padding-top: 5px;
        color: var(--btcolor);
      }
    }

    @include tablet() {
      height: 35px;
      padding: 0 5px;
    }
  }

  .logInContent {
    padding-top: var(--wrap);
    padding-left: 2rem;
    padding-right: 2rem;
    isolation: isolate;
    display: flex;
    flex-direction: column;

    .MuiTelInput-TextField {
      background-color: $yellowprimary;
    }

    .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root:hover
      .MuiOutlinedInput-notchedOutline {
      border-color: #e93055d5;
      border-width: 1px;
    }

    .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
      .MuiOutlinedInput-notchedOutline {
      border-color: $redprimary;
      border-width: 1px;
    }

    .getPhoneState {
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $yellowprimary inset !important;
      }
      > button:nth-child(2){
        min-height: 60px;
      }

      display: flex;
      flex-direction: column;

      .phoneTextField {
        // flex: 2;
        // width: max-content;
      }
    }

    .getOtpState {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: -webkit-min-content;
      width: -moz-min-content;
      width: min-content;

      .MuiOtpInput-TextField {
        background-color: $yellowprimary;
        width: 2rem;

        @include laptop() {
          width: 1.9rem;
        }

        @include tv() {
          width: 3rem;
        }

        @include tablet() {
          width: 4rem;
        }

        @include mobile-large() {
          width: 3.5rem;
        }

        @include mobile() {
          width: 3rem;
        }
      }
      .MuiInputBase-input {
        padding-left: 0;
        padding-right: 0;
      }
      .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
        .MuiOutlinedInput-notchedOutline {
        border-color: #e93055d5;
        border-width: 1px;
      }

      .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
        .MuiOutlinedInput-notchedOutline {
        border-color: $redprimary;
        border-width: 1px;
      }

      .css-tccdxk {
        gap: 15px;

        @include mobile-large() {
          gap: 6px;
        }

        @include mobile() {
          gap: 4px;
        }
      }
    }

    .buttonWrapper {
      position: relative;
      // transform: translateX(0);
      top: auto;
      display: flex;
      flex-wrap: nowrap;
      right: auto;
      width: -webkit-min-content;
      width: -moz-min-content;
      width: min-content;
      margin-top: 1rem;
      padding: 0;
      height: auto;
      align-self: center;

      .requestAndsendOtpBtn {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 0;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        height: 3rem;
      }

      .send {
        display: flex;
        flex-wrap: nowrap;
        color: var(--btcolor);
        font-size: 1.2rem;
        padding: 10px 10px;
        // border: 1px solid var(--btcolor);
      }
    }
    .googleButton {
      display: flex;

      // flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      color: #545454;
      background-color: transparent;
      font-size: 1.2rem;
      padding: 20px 10px;
      border: 2px solid $redprimary;
      border-radius: 5px;
      align-self: stretch;
      height: 56px;
      // width: max-content;
      .googleLogo {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
    .error_message {
      padding: 10px 0px;
      color: red;
    }
  }
}

.circleAnimationSvgWrapper {
  --stroke-color: #b00e2f;
  height: var(--height);
  align-self: flex-start;

  .circleSvg {
    width: var(--height);
    height: var(--height);

    path {
      stroke: var(--stroke-color);
    }

    :nth-child(1) {
      transform-origin: center center;
      -webkit-animation: rotate 21s linear forwards infinite;
      animation: rotate 21s linear forwards infinite;
    }

    :nth-child(2) {
      transform-origin: center center;
      -webkit-animation: rotate 7s linear forwards infinite;
      animation: rotate 7s linear forwards infinite;
    }
  }

  @-webkit-keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }

    100% {
      transform: rotateZ(360deg);
    }
  }

  @keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }

    100% {
      transform: rotateZ(360deg);
    }
  }

  border-radius: 50%;
  // background-color: pink;
  height: var(--height);
  width: var(--height);
  position: relative;

  .hoverLayer {
    display: flex;
    background-color: var(--stroke-color);
    position: absolute;
    top: 0;
    height: var(--height);
    width: var(--height);
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    transform: translateY(100%);
    transition: transform 1s ease;
  }

  overflow: hidden;

  .arrow {
    position: absolute;
    top: 0;
    left: 0;

    :nth-child(1) {
      display: none;
    }

    :nth-child(2) {
      display: none;
    }

    :nth-child(3) {
      // transform-origin: center center;
      // animation: rotate 7s linear forwards infinite;
      transition: stroke 0.6s ease;
      stroke: var(--stroke-color);
    }
  }
}

.circleAnimationSvgWrapper:hover {
  cursor: pointer;

  .hoverLayer {
    transform: translateY(0px);
  }

  svg {
    :nth-child(3) {
      stroke: var(--stroke-alternate-color);
    }
  }
}

.circleLoadingAnimation {
  --stroke-color: #b00e2f;
  // max-width: var(--maxwidth);
  --height: min(var(--maxwidth), calc(100vw - 2rem));
  width: var(--height);
  height: var(--height);
  // width:  200px ;
  align-self: center;
  border-radius: 50%;
  // background-color: pink;
  // height: var(--height);
  // width: var(--height);
  position: relative;
  overflow: hidden;

  .circleSvg {
    width: 100%;
    height: 100%;
    position: absolute;

    path {
      stroke: var(--stroke-color);
    }

    :nth-child(1) {
      transform-origin: center center;
      -webkit-animation: rotate 21s linear forwards infinite;
      animation: rotate 21s linear forwards infinite;
    }

    :nth-child(2) {
      transform-origin: center center;
      -webkit-animation: rotate 7s linear forwards infinite;
      animation: rotate 7s linear forwards infinite;
    }

    :nth-child(3) {
      display: none;
    }
  }

  @-webkit-keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }

    100% {
      transform: rotateZ(360deg);
    }
  }

  @keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }

    100% {
      transform: rotateZ(360deg);
    }
  }

  div {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    justify-content: center;
    align-items: center;
    color: $redprimary;
    transition: color 0.6s ease;
  }
  .hoverLayer {
    display: flex;
    background-color: var(--stroke-color);
    position: absolute;
    top: 0;
    height: var(--height);
    width: var(--height);
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    transform: translateY(100%);
    transition: transform 1s ease;
  }
}
.hoverAnimation:hover {
  cursor: pointer;
  div {
    color: white;
  }
  .hoverLayer {
    transform: translateY(0px);
  }
}

.raisedbox {
  // padding: 1px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.space-width {
  width: var(--wrap);
}

.inputBox {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  .errorContainer {
    display: none;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
  .errorMessage {
    font-size: 1rem;
    margin-top: 5px;
    color: #f27260;
    font-family: "Secondary";
    display: block;
  }
  // input:invalid[focused="true"] {
  //   // border: 1px solid red;
  // }
  input:invalid[focused="true"] {
    margin-bottom: 1px;
  }

  input:invalid[focused="true"] ~ .errorContainer {
    display: flex;
    transform: translateX(0px);
  }
 

  .inputBoxLabel {
    font-size: 1.1rem;
    font-weight: 500;
    font-family: "Secondary";
    color: $redprimary;
    transition: 0.5s;
    // margin-bottom: 0.5rem;
  }
  .inputBoxInput {
    font-family: "Secondary";
    height: 2rem;
    // border-radius: 5px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid $redprimary;
    // padding-left: 1rem;
    font-size: 1.3rem;
    font-weight: 500;
    color: #000;
    margin-bottom: 21px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #000;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #c0c0c000;
  }
  input :hover,
  input:focus,
  input:active {
    outline: none;
  }
  
}

.dropdownBox {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 1rem;
  --width: 100%;
  .dropdownBoxLabel {
    font-size: 1.1rem;
    font-weight: 500;
    color: $redprimary;
    transition: 0.5s;
    // margin-bottom: 0.5rem;
    font-family: 'Secondary';
  }
  .dropDownBoxData {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2rem;
    border-bottom: 1px solid $redprimary;
    align-items: flex-start;
    font-size: 1.1rem;
    font-weight: 100;
    color: #000;
    margin-top: 2px;
    font-family: "Secondary";
  }
  select {
    margin-top: 3px;
    font-family: cursive, sans-serif;
    outline: 0;
    height: 2.1rem;
    font-size: 1.3rem;
    background: $yellowprimary;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='darkred'><polygon points='0,0 100,0 50,50'/></svg>")
      no-repeat;
    background-size: 12px;
    background-position: calc(100% - 20px) center;

    color: #fff;
    border: none;
    color: #000;
    border-bottom: 1px solid $redprimary;
    // padding: 4px;
    height: 2rem;
    font-family: "Secondary";
    border-radius: 0;

    option {
      //   &:hover {
      //     background-color: $redprimary;
      //     color: black;
      //   }

      &:checked {
        //   background-color: $redprimary;
        color: black;
      }
    }

    // option:active {
    //   background-color: $redprimary;
    // }
  }
  // .dropdownContent {
  //     display: none;
  //     position: absolute;
  //     background-color: #f9f9f9;
  //     width: var(--width);
  //     box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  //     // padding: 12px 16px;
  //     top:100%;
  //     z-index: 1;
  //   }

  //   &:hover .dropdownContent {
  //     display: block;
  //   }
}
option {
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  padding: 1rem;
  color: $redprimary;
  &:hover {
    background-color: $redprimary;
    color: black;
  }
}

.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  height: 3rem;
  margin-top: 2rem;
  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    // top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 3px solid $redprimary;
  }
  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: $redprimary;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  .label {
    margin-left: 1rem;
    margin-top: 4px;
    font-size: 1.7rem;
    color: $redprimary;
  }
}
.orDivider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  .line {
    width: 100%;
    max-width: 53px;
    height: 1px;
    background-color: #aaaaaa;
  }
  .or {
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.7rem;
    color: #aaaaaa;
  }
}



.pageTittleFont{
  font-size: 9rem;
  @include laptop() {
    font-size: 5.875rem;
  }
  @include tablet() {
    font-size: 2.5rem;
  }
}

.rupeesymbol{
  font-family: 'Secondary';
  line-height: 1.5rem;
}