@import 'common.scss';

.contactWrapper {
    h1 {
        margin-top: 0;
        margin-bottom: 1.5rem;
        @include laptop() {
            margin-bottom: 1.25rem;
        }
    }

    h2 {
        margin-top: 0;
        font-size: 3.5rem;

        @include laptop() {
            font-size: 3rem;
        }

        @include tablet() {
            font-size: 2.5rem;
        }

        @include mobile-large() {
            font-size: 2rem;
        }

        @include mobile() {
            font-size: 1.5rem;
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        font-size: 1.2rem;
        bottom: var(--wrap);
        left: var(--wrap);
        right: var(--wrap);
        display: flex;
        color: white;
        // padding-left: var(--wrap);
        // padding-right: var(--wrap);
        justify-content: space-between;

        a {
            color: white;
            text-decoration: none;
            margin-left: 5px;
            margin-right: 5px;
            max-width: 250px;
            text-align: center;
        }

        div {
            display: flex;

            @include tablet() {
                flex-direction: column;
            }

        }

        @include tablet() {
            left: 5px;
            right: 5px;
        }
    }
}