@import "common.scss";
.editProfilePage {
    background-color: $yellowprimary;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100dvh;
    
    &load {
      justify-content: center;
    }
  
    .editProfileContent {
      padding-top: var(--header-height);
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-items: center;
      width: 70%;
      .pagetitle {
        color: $redprimary;
        align-self:  center;
        @include tablet {
          font-size: 48px;
        }
      }
      @include tablet {
        width: 90%;
      }
      
      .formContainer {
        width: 100%;
        display: grid;
        margin-top: 1rem;
        // margin-bottom: 1rem;
        grid-template-columns: 1fr 1fr;
        row-gap: 1rem;
        column-gap: 1rem;
        @include tablet {
          grid-template-columns: 1fr;
        }
      }
      
      .editProfilePageFinalFotter {
        width: 100%;
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        margin-top: 2rem;

        // position: fixed;
        // bottom: 0;
        
        .totalContent {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .totalText {
            font-size: 1.7rem;
            font-weight: 500;
            color: #5b6874;
            @include tablet {
              font-size: 1.2rem;
            }
          }
          .totalPrice {
            font-size: 2.5rem;
            font-weight: bold;
            color: $redprimary;
            @include tablet {
              font-size: 2.7rem;
            }
          }
        } 
        
        .buttonWrapper {
          margin-left: 1rem;
          display: flex;
          width: fit-content;
          padding: 0;
          height: 3.5rem;
          .paymentButtons {
            height: 3rem;
            display: flex;
            font-size: 1.2rem;
            justify-content: center;
            align-items: center;
            align-content: center;
            color: var(--btcolor);
            @include tablet {
              font-size: 1.2rem;
            }
            // background-color: var(--btBgcolor);
          }
        }
      }
    }
  }