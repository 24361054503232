@import "common.scss";

.profilePageWrapper {
  background-color: $yellowprimary;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100dvh;
  padding-top: var(--header-height);
  .pagetitle {
    // font-size: 72px;
    // font-weight: 400;
    color: $redprimary;
    align-self: center;
    @include tablet {
      font-size: 48px;
    }
  }
  .profileConatiner {
    background-color: white;
    width: 70%;
    @include tablet {
      width: 100%;
    }
    // row-gap: 1rem;
    margin: 44px 2rem 1rem 2rem;

    display: flex;
    // justify-content: space-between;
    .profileGrid {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;

      .gridItem {
        display: flex;
        padding: 1rem;
        .title {
          min-width: 4rem;
          color: $redprimary;
        }
      }
      @include tablet {
        grid-template-columns: 1fr;
      }
    }
    .editButton {
      cursor: pointer;
      padding: 12px;
      margin: 12px;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }

  .bookingAndDonationSelector {
    display: flex;
    position: sticky;
    color: white;
    margin: 0 2rem;
    padding: 2rem 0;
    z-index: 1;
    // top:var(--header-height);
    top: 0px;
    width: 100%;
    justify-content: center;
    background-color: $yellowprimary;
    button {
      font-size: 42px;
      font-weight: 400;
      font-style: normal;
      background-color: transparent;
      border: none;
      outline: none;
      color: white;
      cursor: pointer;
      font-family: "Regular";
      &.active {
        color: $redprimary;
        text-decoration: underline;
        text-underline-offset: 12px;
      }
      @include tablet {
        font-size: 30px;
      }
    }
    .bookingSelector {
      // margin-right: 2rem;
      margin-right: 0rem;
    }

  }
  .noBookingAndDonationContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    border-top:  2px solid $redprimary;
    border-bottom:  2px solid $redprimary;
    padding: 2rem 0;
    margin-bottom: 3rem;
    @include tablet {
      width: 90%;
      margin: 1rem 0.5rem 1rem 0.5rem;
      // height: 4rem;
    }
    .noBookingAndDonationText{
      font-size: 1.4rem;
      font-weight: 500;
      @include tablet {
        font-size: 1.2rem;
      }
      @include mobile {
        font-size: 1rem;
      }
      margin: 1rem 0;
    }
    .bookNowWrapper{
      display: flex; 
      width: 100%;
      height: 50px;
      justify-content: center;
      align-self: center;
      align-items: center;
      cursor: pointer;
      --height: 50px;
      --stroke-color: var(--redprimary);
      --stroke-alternate-color: white;
      @include mobile {
        height: 40px;
        --height: 40px;
      }
      .bookNowText {
        margin-left: 20px;
        color: var(--stroke-color);
        font-size: 1.5rem;
      }
    }
  }

  .bookingAndDonationContainer {
    border-bottom: 1px solid $redprimary;
    padding-bottom: 1rem;
    width: 70%;
    display: flex;
    align-items: center;
    margin: 1rem 2rem 14px 2rem;

    @include tablet {
      width: 90%;
      margin: 1rem 0.5rem 1rem 0.5rem;
      // height: 4rem;
    }
    // @include mobile {
    //   height: 3rem;
    // }
    // background-color: rgba(161, 187, 109, 0.44);
    img {
      height: 54px;
      // height: fit-content;
      position: relative;
      display: flex;
      margin: 0 1rem 0 0;
      object-fit: cover;
      aspect-ratio: 1;
      align-self: start;
      @include tablet {
        margin: 0rem 0.5rem 0 0;
      }
    }
    .textContainer {
      color: #323232;
      display: flex;
      flex-direction: column;
      margin-right: auto;
      justify-content: space-between;
      height: 100%;
      .title {
        font-weight: 500;
        font-size: 2rem;
        line-height: 2rem;
        @include tablet {
          font-size: 1.8rem;
          line-height: 2rem;
        }
        @include mobile {
          font-size: 1.2rem;
          line-height: 1.2rem;
        }
      }
      .subtitle {
        display: flex;
        align-items: center;
        height: 1.25rem;
        .bookingType{
          border-radius: 0.25rem;
          padding: 0.125rem 0.375rem;
          font-weight: 500;
          font-size: 1.125rem;
          font-family: "Secondary";
          @include tablet {
            font-size: 1rem;
          }
          @include mobile {
            font-size: 0.75rem;
          }
        }
        @include mobile {
          padding-top: 10px;
        }
        .aarti{
          border: 1px solid #B00E2F;
          color : #B00E2F;
        }
        .pooja{
          border: 1px solid #FD6A00;
          color : #FD6A00;
        }
        .dateTime {
          font-weight: 500;
          font-size: 1.125rem;
          font-family: "Secondary";
          @include tablet {
            font-size: 1.125rem;
          }
          @include mobile {
            font-size: 1rem;
          }
        }
      }
    }

    .totalContainer {
      display: flex;
      align-items: center;
      cursor: pointer;
      .statusIcon {
        width: 1.5rem;
        height: 1.5rem;
        align-self: center;
      }
      .amount {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 2.5rem;
        @include tablet {
          font-size: 1.7rem;
        }
        @include mobile {
          font-size: 1rem;
        }
      }
      .goIcon {
        margin-left: 0.5rem;
        width: 1.5rem;
        height: 1.5rem;
        align-self: center;
        @include mobile {
          margin-left: 0rem;
        }
      }
    }
  }
}
