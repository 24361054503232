$redprimary: #b00e2f;
$yellowprimary: #fee5ca;
.imageWrapper {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    // background-color: rgba(0, 255, 255, 0.24);   
    // .img{
    //     width: 25%;
    // }
    overflow: hidden;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;


    .box {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #fee5cad3;
        justify-content: center;
        align-items: center;
        // left: -100%;
    }





    
    @-webkit-keyframes fromLeft {
        0% {
            top: 0;
            left: -100%
        }
        
        100% {
            top: 0;
            left: 0%;
        }
    }





    
    @keyframes fromLeft {
        0% {
            top: 0;
            left: -100%
        }
        
        100% {
            top: 0;
            left: 0%;
        }
    }
    @-webkit-keyframes toLeft {
        to {
            left: -100%;
            top: 0;
        }
    }
    @keyframes toLeft {
        to {
            left: -100%;
            top: 0;
        }
    }

    @-webkit-keyframes fromRight {
        from {
            top: 0;
            left: 100%
        }

        to {
            left: 0;
        }
    }

    @keyframes fromRight {
        from {
            top: 0;
            left: 100%
        }

        to {
            left: 0;
        }
    }
    @-webkit-keyframes toRight {
        to {
            left: 100%;
        }
    }
    @keyframes toRight {
        to {
            left: 100%;
        }
    }

    @-webkit-keyframes fromTop {
        from {
            top: -100%;
            left: 0;
        }
        to {
            top: 0;
        }
    }

    @keyframes fromTop {
        from {
            top: -100%;
            left: 0;
        }
        to {
            top: 0;
        }
    }
    @-webkit-keyframes toTop {
        to {
            top: -100%;
        }
    }
    @keyframes toTop {
        to {
            top: -100%;
        }
    }
    

    @-webkit-keyframes fromBottom {
        from {
            top: 100%;
            left: 0;
        }
        to {
            top: 0;
        }
    }
    

    @keyframes fromBottom {
        from {
            top: 100%;
            left: 0;
        }
        to {
            top: 0;
        }
    }
     @-webkit-keyframes toBottom {
        to {
            top:100%;
        }
    }
     @keyframes toBottom {
        to {
            top:100%;
        }
    }

}

.imageWrapper:nth-child(odd) {
    border-radius: 10% 0 0 0;
}

.imageWrapper:nth-child(even) {
    border-radius: 0 10% 0 0;
}