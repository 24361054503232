@import 'common.scss';
#refundCancellationPolicyPage{
    background-color: $yellowprimary;
    margin: 0;
    color: $redprimary;
    display: flex;
    justify-content: center;
    min-height: 100vh;
}
#refundCancellationPolicyWrapper{
    padding: 0 20px ;
    padding-top: var(--wrap);
    max-width: 1000px;
    text-align: justify;
    // background-color: $yellowprimary;
    // h1{
    //     margin-top: 0;
    // }
    // p{
    //     margin-bottom: 0;
    // }
    display: flex;
    flex-direction: column;
    h3{
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 600;
    }
    a{
        color: rgba(0, 0, 255, 0.822);
    }
}