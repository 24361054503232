@import "common.scss";

.donationPage {
  background-color: $yellowprimary;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100dvh;
  justify-content: center;
  .donationPage{
    justify-self: center;

  }
  .bookPageContent {
    padding-top: var(--header-height);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    width: 70%;
    @include tablet {
      width: 90%;
    }

    .bookPageContentTitle {
      color: $redprimary;
    }
    .bookPageForm {
      width: 100%;
      display: grid;
      margin-top: 2rem;
      margin-bottom: 2rem;
      grid-template-columns: 1fr 1fr;
      row-gap: 1rem;
      -moz-column-gap: 1rem;
      column-gap: 1rem;
      @include tablet {
        grid-template-columns: 1fr;
      }
      @include tablet {
        grid-template-columns: 1fr;
      }
    }
    .buttonWrapper {
        margin-left: 1rem;
        display: flex;
        width: fit-content;
        padding: 0;
        height: 3.5rem;
        align-self: self-end;
        margin-bottom: 2rem;
        .paymentButtons {
          height: 3rem;
          display: flex;
          font-size: 1.2rem;
          justify-content: center;
          align-items: center;
          align-content: center;
          color: var(--btcolor);
          @include tablet {
            font-size: 1.2rem;
          }
          // background-color: var(--btBgcolor);
        }
      }
  }
}
