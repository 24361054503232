@import "common.scss";

.pageNotFoundWrapper{
    background-color: $yellowprimary;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100dvh - var(--header-height));
    padding-top: var(--header-height);
    #_404 {
        margin: 1rem 0;
        @include laptop {
            width: 70%;
        }
        
    }
    .backToHomeWrapper{
        margin-top: 2rem;
        display: flex; 
        width: 100%;
        height: 100px;
        justify-content: center;
        align-self: center;
        align-items: center;
        cursor: pointer;
        --height: 100px;
        --stroke-color: var(--redprimary);
        --stroke-alternate-color: white;
        @include mobile {
          height: 60px;
          --height: 60px;
        }
        .backToHomeText {
          margin-left: 20px;
          color: var(--stroke-color);
          font-size: 1.5rem;
        }
      }
}