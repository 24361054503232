@import "common.scss";

body {
  font-size: 1.6rem;
  background: var(--bg);
}

.container {
  max-width: 100vw;
  //   padding: 4rem 1rem;
  margin: 0 auto;
  background-color: $yellowprimary;
}

.swiper_container {
  // height: 52rem;
  padding: 2rem 0;
  position: relative;

  @include mobile-large() {
    padding: 2rem 1rem;
  }
}

.swiper-slide {
  width: 37rem;
  height: 42rem;
  position: relative;
  overflow: hidden;
}

@include mobile-large {
  // .swiper_container {
  //     height: 47rem;
  // }

  .swiper-slide {
    width: 28rem !important;
    // height: 36rem !important;
    height: calc(1.33 * calc(100vw - 5rem)) !important;
    max-width: calc(100vw - 1rem) !important;
    // aspect-ratio: 4/3 !important;
  }

  .swiper-slide img {
    height: calc(1.33 * calc(100vw - 5rem)) !important;
  }

  .swiper-slide img,
  .blurhash {
    // width: 28rem !important;
    // height: 36rem !important;
    height: calc(1.33 * calc(100vw - 5rem));
    border-radius: 2rem;
    max-width: calc(100vw - 1rem) !important;
  }
}

.swiper-slide img,
.blurhash {
  width: 37rem;
  height: 42rem;
  border-radius: 2rem;
  -o-object-fit: cover;
  object-fit: cover;
}

.galleryWrapper {
  background-color: $yellowprimary;
  display: flex;
  flex-direction: column;
  min-height: calc(100cqh);

  .title {
    padding-top: var(--wrap);
    color: $redprimary;
    text-align: center;
  }

  .circleLoadingAnimation {
    // height: max-content;
    // height: 500px;
    // height: auto;
    flex: 2 1 auto;
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
    justify-self: center;
    // align-self: center;
  }

  .imagesGridWrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .imagesGrid {
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content;
      padding: 0 2rem;
      margin-bottom: 10vh;
      display: grid;
      -moz-column-gap: 1.2%;
      column-gap: 1.2%;
      row-gap: 1.2%;
      // width: 100%;
      grid-template-columns: 24.1% 24.1% 24.1% 24.1%;

      @include tablet() {
        -moz-column-gap: 2vw;
        column-gap: 2vw;
        row-gap: 2vw;
        padding: 0 2vw;
        grid-template-columns: 49% 49%;
      }

      @include mobile() {
        -moz-column-gap: 2vw;
        column-gap: 2vw;
        row-gap: 2vw;
        padding: 0 2vw;
        grid-template-columns: 1fr;
      }

      // img,
      // .blurhash {
      //     width: 100% !important;
      //     // height: 100%;
      //     aspect-ratio: 3/4 !important;
      //     -o-object-fit: cover;
      //        object-fit: cover;
      //     border-radius: 20px;

      // }
      .item {
        background-color: white;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        img,
        .blurhash {
          width: calc(100% - 52px );
          // width: 100%;
          aspect-ratio: 3/4 !important;
          margin: 26px 26px;
          height: auto;
          border-radius: 5px;
        }
        h2 {
          color: $redprimary;
          font-weight: 600;
          width: calc(100% - 52px);
          margin: 4px 26px 13px 26px;
          font-size: 2rem;
          text-align: center;
        }
      }
    }
  }
}

#layoutWrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  --btBgcolor: #fee5ca;
  // background-color: var(--color-overlay);
  z-index: 5;
  justify-content: center;

  img {
    -o-object-fit: contain;
    object-fit: contain;
    max-width: 100%;
    -o-object-position: center;
    object-position: center;
  }

  .buttonWrapper {
    margin: 0;
    position: fixed;
    right: var(--wrap);
    bottom: var(--wrap);

    .text {
      color: var(--btcolor);
      font-size: 1rem;
      @include tablet() {
        font-size: 0.88rem;
      }
      @include mobile-large() {
        display: none;
      }
    }
    .downloadbtn {
      padding: 1px;
      color: var(--btcolor);
    }
  }
}
