@import "common.scss";

.donationTransactionPage {
  background-color: $yellowprimary;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100dvh;
  padding-top: var(--header-height);
  &load {
    justify-content: center;
  }
  overflow: hidden;
  .pagetitle {
    // font-size: 72px;
    // font-weight: 400;
    color: $redprimary;
    @include tablet {
      font-size: 48px;
    }
  }
  .bookingAndDonationContainer {
    border-bottom: 1px solid $redprimary;
    padding-bottom: 8px;
    width: 70%;
    display: flex;
    height: 5rem;
    align-items: center;
    margin: 1rem 2rem 2rem 2rem;

    @include tablet {
      width: 90%;
      margin: 1rem 0.5rem 1% 0.5rem;
      height: 4rem;
    }
    @include mobile {
      height: 3rem;
    }
    // background-color: rgba(161, 187, 109, 0.44);
    img {
      height: 98%;
      position: relative;
      display: flex;
      margin: 0 1rem 0 0;
      object-fit: cover;
      aspect-ratio: 1;
      @include tablet {
        margin: 0rem 0.5rem 0 0;
      }
    }
    .textContainer {
      color: #323232;
      display: flex;
      flex-direction: column;
      margin-right: auto;
      justify-content: space-between;
      height: 98%;
      .title {
        font-weight: 500;
        font-size: 2.5rem;
        @include tablet {
          font-size: 2rem;
        }
        @include mobile {
          font-size: 1.2rem;
        }
      }
      .dateTime {
        font-weight: 500;
        font-size: 2.3rem;
        @include tablet {
          font-size: 1.7rem;
        }
        @include mobile {
          font-size: 1rem;
        }
      }
    }

    .totalContainer {
      display: flex;
      align-items: center;
      .amount {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 2.5rem;
        @include tablet {
          font-size: 1.7rem;
        }
      }
    }
  }

  .detailsContainer {
    display: flex;
    justify-content: flex-start;
    align-self: center;
    align-items: center;
    width: 70%;
    margin: 10px 0px 10px 0px;
    @include tablet {
      width: 90%;
      margin: 6px 0.5rem 6px 0.5rem;
    }
    .name {
      font-size: 40px;
      font-weight: 500;
      color: $redprimary;
      min-width: 400px;
      overflow-wrap: break-word;

    //   margin-left: 1rem;
      @include tablet {
        font-size: 35px;
        min-width: 240px;
        // margin-left: 0.5rem;
      }
      @include mobile {
        font-size: 18px;
        // margin-left: 0px;
        min-width: 110px;
      }
    }
    .value {
      color: #323232;
      font-family: Helvetica;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 19.5px; /* 97.5% */
      @include tablet {
        font-size: 18px;
      }
      @include mobile {
        font-size: 13px;
      }
    }
  }
}
